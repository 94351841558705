<!--Author: zjy   
	Date: 2020.12.21
	Description:首页轮播图组件
	History: 暂无
-->
<template>
  <div id="swiper">
    <div class="block">
      <el-carousel type="card" trigger="click" arrow="never">
        <el-carousel-item v-for="(item, index) in picList" :key="index">
          <img
            class="showitem"
            :src="imgUrlFront + item.ImgUrl"
            v-if="item.ImgUrl != undefined"
            alt="找不到资源"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "Swiper",
  data() {
    return {
      imgUrlFront: "", //图片前缀url
    };
  },
  props: {
    picList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {},
  created() {
    this.imgUrlFront = window.sessionStorage.getItem("apiImgUrl");
  },
};
</script>

<style lang="scss">

@media( max-width: 1200px){
  #swiper{
    display: flex;
    justify-content: center;
    .block{
      max-width: 100%;
      max-height: 100%;
      // width: 80%;
      // max-width: 750px;
      // height: 80%;
      // max-height: 220px;
      margin-top: 20px;
      ul {
        margin: 5px auto;
      }
      .showitem {
        // width: 100%;
        // height: 35%;
        border-radius: 10px;
      }
    }
    @media (max-width: 750px) {
       .el-carousel__container {
          width: 100%;
          max-height: 120px;
          .el-carousel {
            height: 100%;
            width: 100%;
            }
      
        }
    }
      @media (min-width: 751px) and (max-width: 1200px) {
       .el-carousel__container {
          width: 100%;
          max-height: 220px;
          .el-carousel {
            height: 100%;
            width: 100%;
            }
      
        }
    }

    // .el-carousel__container {
    //   width: 100%;
    //   max-height: 220px;
    //   .el-carousel {
    //     height: 100%;
    //     width: 100%;
    //     }
  
    // }
    
  }
}
@media( min-width: 1201px){}
  #swiper {
  display: flex;
  justify-content: center;
  .block {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    max-height: 370px;
    margin-top: 20px;
    ul {
      margin: 5px auto;
      li {
        margin: 0 10px 51px 10px;
      }
    }
    .showitem {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .el-carousel__container {
    .el-carousel {
      height: 100%;
      width: 100%;
      }
 
  }
  .el-carousel__indicator--horizontal {
    padding: 0;
  }
  .el-carousel__button {
    display: block;
    width: 10px;
    height: 10px;
    background-color: #000;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50%;
  }
  }
  .el {
    height: 100%;
    width: 100%;
    margin-left: auto;
  }


</style>
