<!--Author: zjy   
	Date(该日期为最后修改日期): 2020.12.21
	Description:产品页,用于展示
	History: 暂无
-->
<template>
	<div>
		<swiper :picList="picList"></swiper>
		<product-style></product-style>
		<footer-page></footer-page>
	</div>
</template>

<script>
	import swiper from '@/components/common/swiper/Swiper.vue'
	import footerPage from '@/components/common/footer/footerPage.vue'
	import ProductStyle from '@/components/content/product/ProductStyle.vue'
	
	export default{
		name: 'Product',
		data(){
			return {
				picList:[]
			}
		},
		components:{
			swiper,
			footerPage,
			ProductStyle,
		},
		created(){		
			this.$store.commit('updateIsActiveIndex',1)
			//请求轮播图接口
			let requestParams = {'Status':3,'BelongAreaID':'1'}
			this.$axios('API/Get_Banner',requestParams).then( res => {
				this.picList = res.data
			}).catch( err => { console.log(err) })	
		},
		
	}
</script>

<style>
</style>
