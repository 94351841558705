<template>
	<div id="footerbox">
		<div id="footer">
			<div class="footer_Info">
				<div class="footer_left">
					<img class="logo" src="~/assets/images/nav/logo_bottom.png" alt="not found">
					<ul>
						<li style="font-size: 18px;font-weight: 700;">江苏数字矩阵科技有限公司</li>
						<li><span>行政运营部：</span>南京市江宁区佛城西路88号复地朗香别墅261幢1-3F</li>
						<li><span>产品研发部：</span>南京市建邺区江心洲江岛智立方D座1单元4F</li>
						<li><span>商务合作：</span>bd@jsszjz.cn</li>
						<li><span>联系电话：</span>025-85207326</li>
					</ul>
				</div>
				<div class="footer_right">
					<ul>
						<h4>常用功能</h4>
						<li @click="toOrder()">订单查询</li>
						<li @click="toAgreement()">用户协议</li>
						<li @click="toSecretAgreement()">隐私协议</li>
						<li @click="tohignCompanytest()">高企评测</li>
						<!-- <li @click="topolicyMatch()">政策解读</li> -->
						<!-- <li>隐私服务</li> -->
						<!-- <li>退款问题</li> -->
						<!-- <li>发票说明</li> -->
						<!-- <li>运费政策</li> -->
						<!-- <li>售后政策</li> -->
					</ul>
					<ul>
						<h4>热门产品</h4>
						<li
						v-for="(item,index) in espList"
						:key="index"
						@click="toProduct(index)"
						>{{item.ServiceTypeName}}</li>
					
					</ul>
					<ul>
						<h4>服务流程</h4>
						<li @click="toProduct(1)">选择服务项目</li>
						<li @click="toOrder()">下单支付</li>
						<!-- <li>开始服务</li>
						<li>完成服务</li>
						<li>售后服务</li> -->
					</ul>
					<ul>
						<h4>关于我们</h4>
						<li @click="toAboutus">公司简介</li>
						<li @click="toAboutus1">联系我们</li>
						<li @click="toAboutus2">加入我们</li>
						<!-- <li>免责声明</li> -->
						<!-- <li>售后服务</li> -->
					</ul>
				</div>
			</div>
			<footer-bottom></footer-bottom>
		</div>
	</div>
</template>

<script>
	import footerBottom from './footerbottom.vue'
	export default{
		name: 'footerPage',
		data(){
			return{
				espList: [], //热门产品类别
			}
		},	
		components:{
			footerBottom,
		},
		computed:{
			userinfo(){
				return this.$store.state.userinfo
			}
		},
		created() {
			this.init()
		},
		methods:{
			//初始化数据
			init(){
				//传入参数
				let requestParams = { Status: 3, TopNum: 5, IsShowIndex: "1" };
				//请求服务平台接口
				this.$axios("API/Get_ServiceType", requestParams)
				.then((res) => {
					this.espList = res.data;
				})
				.catch((err) => {
					console.log(err);
				});
			},
			//点击热门产品类别跳转
			toProduct(index){
				this.$router.push({
					path:'/product',
					query: {
						FLnkID: this.espList[index].FLnkID,
						OrderID: this.espList[index].OrderID
					}
				})
			},

			toAgreement(){
				let routeData = this.$router.resolve({ name: 'agreement' });
			  window.open(routeData.href, '_blank');
			},
			toSecretAgreement(){
			  let routeData1 = this.$router.resolve({ name: 'agreement1'})
			  window.open(routeData1.href,'_blank')
			},
			toOrder(){
				if(this.userinfo){
					this.$router.push({
						name:'order',
					})
				}else{
					this.$confirm('未登录或登录状态失效', '提示', {
						confirmButtonText: '去登录',
						cancelButtonText: '过会再去',
						type: 'warning'
					}).then(() => {
						this.$router.push('/login')
					}).catch(() => {
					});
				}
				
			},
			toAboutus(){
				this.$router.push({
					name:'companyintro',
				})
				this.$store.dispatch('aboutstore/aboutus_index',0)
			},
			toAboutus1(){
				this.$router.push({
					name:'contactus',
				})
				this.$store.dispatch('aboutstore/aboutus_index',2)
			},
			toAboutus2(){
				this.$router.push({
					name:'recruiting',
				})
				this.$store.dispatch('aboutstore/aboutus_index',1)
			},
			tohignCompanytest(){
				if(this.userinfo){
					this.$router.push({
						name:'highCompanyEvaluation'
					})
				}else{
					this.$confirm('未登录或登录状态失效', '提示', {
						confirmButtonText: '去登录',
						cancelButtonText: '过会再去',
						type: 'warning'
					}).then(() => {
						this.$router.push('/login')
					}).catch(() => {
					});
				}
			
			},
			topolicyMatch(){
				this.$router.push({
					name:'policyMatch'
				})
			}
			
		}
	}
	
</script>

<style lang='scss' scoped>
	@media (min-width: 1201px){
		@import '@/components/common/footer/footerPage.scss';
	}
	@media(max-width: 1200px){
		@import '@/components/common/footer/footerPage_phone.scss';
	}
</style>
