<!--Author: zjy   
	Date(该日期为最后修改日期): 2020.12.21
	Description:产品页之产品内容模块
	History: 暂无
-->
<template>
	<div id="product_kind">
		<div class="section">
			<div>
			<div class="procenter_top">
				<div class="procenter_top_title">
					<img src="~/assets/images/product/productCenter.png" alt="找不到图片">
					<h3>产品中心</h3>
				</div>
				<div class="section_tab">
					<div class="section_tab_kind">
						<span class="tab_title">分类:</span>
						<ul class="tab_title_kind">
							<li v-for="(item,index) in tab_kind" :key="index" @click="selectItem(index)" :class="activeIndex == index ? 'isActive': 'tab_title_hoversytle'">{{item.ServiceTypeName}}</li>
						</ul>
						
					</div>
				</div>
			</div>
			<transition name="el-fade-in-linear">
			<div class="product_page" v-show="!showNone">
				<div class="product_info" v-for="(item,index) in productList" :key="index" @mouseenter="enter_arrow_Selected(index)" @mouseleave="leave_arrow_Selected()" @click="toProdcutDetail(index)">
					<div class="product_info_img">
						<img :src="imgUrlFront+item.ListImageUrl" alt="找不到资源">
					</div>
					<div class="product_info_intro">
						<h5>{{item.ServiceItemName}}</h5>
						<p>{{item.Description}}</p>
						<img :src="currentIndex==index?arrow_Selected:arrow_pic" alt="找不到资源" >
					</div>
				</div>
	
			</div>
			</transition>
			<div v-show="showNone" class="noneProduct">暂无更多哦</div>
		
			<div class="procenter_bottom">
				<!-- 浏览更多 -->
			</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'ProductStyle',
		data(){
			return {
				activeIndex:'', //分类的活跃索引
				activeIndex1:'0', //样式的活跃索引
				tab_kind:[],	//产品类型
				productList:[],	//产品列表
				imgUrlFront:'', //图片前缀
				showNone:0,	//无数据页面 默认不显示
				
				arrow_Selected:require('@/assets/images/product/enter_arrow_selected.png'),
				arrow_pic:require('@/assets/images/product/enter_arrow.png'),
				currentIndex:null,
			}
		},
		computed:{
			
		},
		created() {
			this.imgUrlFront = window.sessionStorage.getItem('apiImgUrl')
			new Promise(resolve => {
				this.$axios('API/Get_ServiceType',{Version:'',Status:3,TopNum:0,IsShowIndex:'0'}).then(res => {
					this.tab_kind = res.data
					resolve(res)
				})
			}).then(resolve => {
				if(resolve.status == 200){
					if(this.$route.query.FLnkID != undefined){
						this.$axios('API/Get_ServiceItem',{Version:'',Status:3,ServiceTypeGuid:this.$route.query.FLnkID}).then( res => {
							if(res.status == 200){
								this.activeIndex = this.$route.query.OrderID - 1
								this.productList = res.data
								this.showNone = 0
							}
							else if(res.status == 204){
								this.showNone = 1
							}
							else if(res.status == 203){
								this.$message.error('操作失败')
							}
						})
					}else{
						this.activeIndex = 0
						this.selectItem(0)
					}
				}
			})
			
			
			
		},
		methods:{
			selectItem(index){
				this.activeIndex = index
				this.$axios('API/Get_ServiceItem',{Version:'',Status:3,ServiceTypeGuid:this.tab_kind[index].FLnkID}).then( res => {
					if(res.status == 200){
						this.productList = res.data
						this.showNone = 0
					}
					else if(res.status == 204){
						this.showNone = 1
					}
					else if(res.status == 203){
						this.$message.error('操作失败')
					}
				})
				
			},
			selectItem1(index){
				this.activeIndex1 = index
			},
			enter_arrow_Selected(index){
				this.currentIndex = index
			},
			leave_arrow_Selected(){
				this.currentIndex = null
			},
			toProdcutDetail(index){

				this.$router.push({
					path:'/prodcut/productdetail',
					query:{
						flnkid : this.productList[index].FLnkID
					}
				})
			}
		},
		
	}
</script>

<style lang="scss" scoped>
	// .animation_div_box{
	// 	  width: 1180px;
	// 		display: flex;
	// 		justify-content: space-between;
	// 		flex-wrap: wrap;
	// 		// padding: 0 10px;
	// }
	// .list-enter-active, .list-leave-active {
  // 	transition: all 1s;
	// }
	// .list-enter, .list-leave-to{
	// 	opacity: 0;
	// 	transform: translateY(30px);
	// }
	@media(min-width: 1201px){
		@import '@/components/content/product/ProductStyle.scss';
	}
	@media(max-width: 1200px){
		@import '@/components/content/product/ProductStyle_phone.scss';
	}
	
</style>
