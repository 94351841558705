<template>
  <div id="footer_bottom_copyright">
    <div class="bottom_line"></div>
    <div class="copyright">
      <p>
        Copyright ©
        {{ "2020-" + new Date().getFullYear() }}
        南京数字矩阵科技有限公司 版权所有 <a href="http://beian.miit.gov.cn/" style="color: #fff;" target="_blank">苏ICP备2021012477号-1</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerBottom",
  data() {
    return {};
  },
};
</script>

<style lang='scss' scoped>
 @media(min-width:1201px){
   @import '@/components/common/footer/footerbottom.scss';
 }
 @media(max-width: 1200px){
   @import '@/components/common/footer/footerbottom_phone.scss';

 }

</style>
